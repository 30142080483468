.basic_cal_wrap table{
  border-collapse: collapse;
  width: 100%;
}
.basic_cal_wrap th, .basic_cal_wrap td{
  border:1px solid #C4C4C4;
  text-align: center;
  vertical-align: top;
}
.basic_cal_wrap .td_title_now_date{
  border:3px solid #7470F9;
}
.dalirant .basic_cal_wrap .td_title_now_date{
  border:3px solid #5d59d1;
}
.basic_cal_wrap th{
  background: #EFEFEF;
  color:#505050;
  padding:5px;
  font-weight: 500;
}

.basic_cal_wrap .th_text{
  min-height: 25px;
  line-height: 25px;
  font-size: 15px;
}
.basic_cal_wrap .th_text_sat{
  color:#0058FF;
}
.basic_cal_wrap .th_text_sun{
  color:#E22F04;
}

.basic_cal_wrap .td_title{
  color:#C4C4C4;
  text-align: left;
  padding:5px;
  font-weight: 500;
}
.basic_cal_wrap .td_title_now_date .td_title{
  color:#7470F9;
}
.dalirant .basic_cal_wrap .td_title_now_date .td_title{
  color:#02061F;
}
.basic_cal_wrap .td_title_select_month{
  color:black;
}
.basic_cal_wrap .td_content_wrap{
  min-height: 10vh;
}
.basic_cal_wrap .td_title_select_month.td_title_sat{
  color:#0058FF;
}
.basic_cal_wrap .td_title_select_month.td_title_sun{
  color:#E22F04;
}

.top_year_month_wrap{
  width: 100%;
}

.top_year_month_wrap .year_span_wrap{
  display: flex;
  justify-content: center;
  line-height: 38px
}
.top_year_month_wrap .center_ym_span{
  @apply px-1;
  font-size: 20px;
  color:#7470F9;
}
.dalirant .top_year_month_wrap .center_ym_span{
  color:#02061F;
}
.top_year_month_wrap .center_ym_span select{
  color:#7470F9;
  background: #fff;
  border: 0;
  font-size: 25px;
  font-weight: bold;
  padding: 2px;
  text-align-last: center;
  -webkit-appearance:none; /* 크롬 화살표 없애기 */
  -moz-appearance:none; /* 파이어폭스 화살표 없애기 */
  appearance:none /* 화살표 없애기 */
}
.dalirant .top_year_month_wrap .center_ym_span select{
  color:#02061F;
}
.top_year_month_wrap .center_ym_span select option{
  font-size:18px;
}
.top_year_month_wrap .year_arrow_btn{
  display: inline-block;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
  line-height: 43px;
}
.top_year_month_wrap .year_arrow_btn img{
  height: 30px;
  padding:5px;
  border:2px solid #ddd;
  border-radius: 5px;
}
.top_year_month_wrap .top_year_month_side_span{
  font-size: 17px;
  color:#505050;
  line-height: 43px;
  font-weight: 500;
}

.basic_cal_middle_wrap{
  position: relative;
  min-height: 58px;
}
.basic_cal_middle_tab_wrap{
  position: absolute;
  width: 80%;
  min-height: 20px;
  bottom: 5px;
  left:0px;
}
.basic_cal_middle_right{
  position: absolute;
  display: inline-block;
  min-width: 50px;
  right: 0px;
  bottom:5px;
}
.basic_cal_middle_right_state_span{
  display: inline-block;
  line-height: 25px;
  padding: 0 5px;
  color:#fff;
  font-size: 14px;
  background: #A2B854;
  font-weight: 400;
}

@media screen and (max-width:800px) {
  .top_year_month_wrap .top_year_month_side_span{
    display: none;
  }
}