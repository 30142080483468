* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.a4page {
  width: 21cm;
  min-height: 29.7cm;
  /* padding: 2cm; */
  padding:40px;
  margin: 0 auto;
  background: #fbfbfb;
}

.a4subpage {
  background: #fff;
  height: 275mm;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }

  .a4page {
    margin: 0;
    border: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}